import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import {
  KatalMonitoringDriver,
  KatalMonitoringDriverOptions,
} from '@amzn/katal-monitoring-aws-driver';
import {
  APIResponseStatusFamily,
  MetricDimension,
  MetricMethod,
  MetricType,
} from 'src/constants/metrics';

const BASE_SITE = 'MulberryApplication';
const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  } else if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  } else {
    const monitoringConfig: KatalMonitoringDriverOptions = {
      url: 'https://vmj6qpa6xb.execute-api.us-east-1.amazonaws.com/prod/v1/monitoring',
    };
    return new KatalMonitoringDriver(monitoringConfig);
  }
};

/**
 * Generates a context for metrics to help identify the data source.
 *
 * For our metrics, the `site` field will remain constant as 'MulberryApplication',
 * while the `serviceName` will be used to identify which of the artist sites it is.
 * This way metrics in CloudWatch will follow the hierarchy: Katal/MulberryApplication/[SiteName].
 *
 * Reference https://katal.amazon.dev/metrics/schema/
 *
 * @param siteName The name of the artist site to which the metrics pertain to
 * @param sessionId (Optional) The id associated with this session
 * @param dimensions String key-value pairs in addition to marketplace that can be used to filter or sort the data in
 * the CloudWatch metrics dashboard
 * @param relatedMetrics  String key-value pairs to be emitted as additional context to any metric that are not part of the cloudwatch dimensions
 */
const makeMetricsContext = (
  siteName: string,
  sessionId?: string,
  dimensions: KatalMetrics.Metric.String[] = [],
  relatedMetrics: KatalMetrics.Metric.String[] = [],
) => {
  const defaultDimensions = [
    new KatalMetrics.Metric.String(MetricDimension.MARKETPLACE, 'USAmazon'),
  ];

  const metricsContext = sessionId
    ? new KatalMetrics.Context.Builder()
        .withSite(BASE_SITE)
        .withServiceName(siteName)
        .withRelatedMetrics([
          new KatalMetrics.Metric.String('session_id', sessionId),
          ...relatedMetrics,
        ])
        .withCloudWatchDimensions(defaultDimensions.concat(dimensions))
        .build()
    : new KatalMetrics.Context.Builder()
        .withSite(BASE_SITE)
        .withServiceName(siteName)
        .withCloudWatchDimensions(defaultDimensions.concat(dimensions))
        .withRelatedMetrics(relatedMetrics)
        .build();
  return metricsContext;
};

/**
 * Generates a KatalMonitoring metrics publisher which publishes metrics to Cloudwatch in the respective account.
 *
 * Reference: https://katal.amazon.dev/metrics/getting-started-guide/
 */
const makeMetricsPublisher = (sessionId?: string): KatalMetrics.Publisher => {
  return new KatalMetrics.Publisher(
    makeMetricsDriver(),
    metricsConsoleErrorHandler,
    makeMetricsContext(BASE_SITE, sessionId),
  );
};

// NOTE: Exposed only to AppInitWrapper component. Use 'record' methods.
export const initialMetricsPublisher = (sessionId?: string): KatalMetrics.Publisher => {
  return makeMetricsPublisher(sessionId);
};

/**
 * Publishes metrics for a click on a given site.
 * @param siteName The name of the site in which the page exists
 * @param actionName The name of the action triggered on click
 * @param sessionId (Optional) The id associated with this session
 * @param pageName (Optional) The name of the page on which the clicked element exists
 * @param elementName (Optional) The name of the element clicked
 * @param id (Optional) The id of the element clicked
 * @param additionalDimensions (Option) The array of key:value pairs for additional metrics
 */
export const recordClick = (
  siteName: string,
  actionName: string,
  sessionId?: string,
  pageName?: string,
  elementName?: string,
  id?: string,
  ...additionalMetrics: { key: string; value: string }[]
) => {
  let dimensions = [new KatalMetrics.Metric.String(MetricDimension.ACTION, actionName)];

  if (elementName) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.ELEMENT, elementName));
  }

  if (pageName) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.PAGE, pageName));
  }

  if (id) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.ID, id));
  }

  if (additionalMetrics) {
    dimensions = dimensions.concat(
      additionalMetrics.map((a) => new KatalMetrics.Metric.String(a.key, a.value)),
    );
  }

  initialMetricsPublisher(sessionId)
    .newChildActionPublisherForMethod(
      MetricMethod.CLICK,
      makeMetricsContext(siteName, sessionId, dimensions),
    )
    .publishCounter(MetricType.COUNTER, 1);
};

/**
 * Publishes metric on the time it took to render a given page of a site.
 * @param siteName The name of the site in which the page exists
 * @param pageName The name of the rendered page
 * @param timeToLoad The time it took for the page to render
 * @param sessionId (Optional) The id associated with this session
 * @param id The ID of the page
 * @param additionalDimensions (Option) The array of key:value pairs for additional metrics
 */
export const recordPageLoadTime = (
  siteName: string,
  pageName: string,
  timeToLoad: number,
  sessionId?: string,
  id?: string,
  ...additionalMetrics: { key: string; value: string }[]
) => {
  let dimensions = [new KatalMetrics.Metric.String(MetricDimension.PAGE, pageName)];
  if (id) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.ID, id));
  }

  if (additionalMetrics) {
    dimensions = dimensions.concat(
      additionalMetrics.map((a) => new KatalMetrics.Metric.String(a.key, a.value)),
    );
  }

  initialMetricsPublisher(sessionId)
    .newChildActionPublisherForMethod(
      MetricMethod.PAGE_LOAD_TIME,
      makeMetricsContext(siteName, sessionId, dimensions),
    )
    .publishTimer(MetricType.TIMER, timeToLoad);
};

/**
 * Publishes metrics to indicate the site page was viewed.
 * @param siteName The name of the site in which the page exists
 * @param pageName The name of the viewed page
 * @param sessionId (Optional) The id associated with this session
 * @param id The ID of the viewed page
 * @param additionalDimensions (Option) The array of key:value pairs for additional metrics
 */
export const recordPageViewed = (
  siteName: string,
  pageName: string,
  sessionId?: string,
  id?: string,
  ...additionalMetrics: { key: string; value: string }[]
) => {
  let dimensions = [new KatalMetrics.Metric.String(MetricDimension.PAGE, pageName)];
  if (id) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.ID, id));
  }
  if (additionalMetrics) {
    dimensions = dimensions.concat(
      additionalMetrics.map((a) => new KatalMetrics.Metric.String(a.key, a.value)),
    );
  }
  initialMetricsPublisher(sessionId)
    .newChildActionPublisherForMethod(
      MetricMethod.PAGE_VIEW,
      makeMetricsContext(siteName, sessionId, dimensions),
    )
    .publishCounter(MetricType.COUNTER, 1);
};

/**
 * Publishes metrics to indicate an error occurred due to the fallback image being used instead of the media central image.
 * @param siteName The name of the site in which the error occurred
 * @param pageName The name of the page in which the error occurred
 * @param sessionId (Optional) The id associated with this session
 * @param imageId The ID of the image
 * @param errorMessage the error message
 * @param additionalDimensions The array of key:value pairs for additional metrics
 */
export const recordImageError = (
  siteName: string,
  pageName: string,
  sessionId?: string,
  imageId?: string,
  errorMessage?: string,
  ...additionalMetrics: { key: string; value: string }[]
) => {
  let dimensions = [new KatalMetrics.Metric.String(MetricDimension.PAGE, pageName)];
  if (imageId) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.ID, imageId));
  }
  if (errorMessage) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.MESSAGE, errorMessage));
  }
  if (additionalMetrics) {
    dimensions = dimensions.concat(
      additionalMetrics.map((a) => new KatalMetrics.Metric.String(a.key, a.value)),
    );
  }
  initialMetricsPublisher(sessionId)
    .newChildActionPublisherForMethod(
      MetricMethod.ERROR,
      makeMetricsContext(siteName, sessionId, dimensions),
    )
    .publishCounter(MetricType.COUNTER, 1);
};

/**
 * Publishes metrics to indicate the specified content was viewed.
 * @param siteName The name of the site in which the content exists
 * @param pageName The name of the page in which the content exists
 * @param componentName The name of the component viewed
 * @param sessionId (Optional) The id associated with this session
 * @param id The ID of the viewed content
 * @param additionalDimensions (Option) The array of key:value pairs for additional metrics
 */
export const recordContentViewed = (
  siteName: string,
  pageName: string,
  componentName: string,
  sessionId?: string,
  id?: string,
  ...additionalMetrics: { key: string; value: string }[]
) => {
  let dimensions = [new KatalMetrics.Metric.String(MetricDimension.PAGE, pageName)];
  if (id) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.ID, id));
  }
  if (componentName) {
    dimensions.push(new KatalMetrics.Metric.String(MetricDimension.ELEMENT, componentName));
  }
  if (additionalMetrics) {
    dimensions = dimensions.concat(
      additionalMetrics.map((a) => new KatalMetrics.Metric.String(a.key, a.value)),
    );
  }
  initialMetricsPublisher(sessionId)
    .newChildActionPublisherForMethod(
      MetricMethod.CONTENT_VIEWED,
      makeMetricsContext(siteName, sessionId, dimensions),
    )
    .publishCounter(MetricType.COUNTER, 1);
};

/**
 * Publishes metrics to indicate an error occurred when calling an API.
 * @param siteName The name of the site in which the error occurred
 * @param apiName The name of the method that errored when called
 * @param additionalDimensions The array of key:value pairs for additional metrics
 */
export const recordAPIError = (
  siteName: string,
  apiName: string,
  responseStatus: number | undefined,
  ...additionalMetrics: { key: string; value: string }[]
) => {
  let dimensions = [new KatalMetrics.Metric.String(MetricDimension.API, apiName)];
  const additionalContext = [];

  if (responseStatus !== undefined) {
    dimensions.push(
      new KatalMetrics.Metric.String(
        MetricDimension.STATUS_FAMILY,
        getStatusFamily(responseStatus),
      ),
    );
    additionalContext.push(
      new KatalMetrics.Metric.String(MetricDimension.STATUS, responseStatus.toString()),
    );
  }

  if (additionalMetrics) {
    dimensions = dimensions.concat(
      additionalMetrics.map((a) => new KatalMetrics.Metric.String(a.key, a.value)),
    );
  }
  initialMetricsPublisher()
    .newChildActionPublisherForMethod(
      MetricMethod.API_ERROR,
      makeMetricsContext(siteName, undefined, dimensions, additionalContext),
    )
    .publishCounter(MetricType.COUNTER, 1);
};

/**
 * Publishes metrics to indicate an API was called.
 * @param siteName The name of the site in which the API was called.
 * @param apiName The name of the method that errored when called
 * @param additionalDimensions The array of key:value pairs for additional metrics
 */
export const recordAPIRequest = (
  siteName: string,
  apiName: string,
  ...additionalMetrics: { key: string; value: string }[]
) => {
  let dimensions = [new KatalMetrics.Metric.String(MetricDimension.API, apiName)];
  if (additionalMetrics) {
    dimensions = dimensions.concat(
      additionalMetrics.map((a) => new KatalMetrics.Metric.String(a.key, a.value)),
    );
  }
  initialMetricsPublisher()
    .newChildActionPublisherForMethod(
      MetricMethod.API_REQUEST,
      makeMetricsContext(siteName, undefined, dimensions),
    )
    .publishCounter(MetricType.COUNTER, 1);
};

const getStatusFamily = (status: number): APIResponseStatusFamily => {
  if (status >= 100 && status < 200) {
    return APIResponseStatusFamily.INFORMATIONAL;
  } else if (status >= 200 && status < 300) {
    return APIResponseStatusFamily.SUCCESS;
  } else if (status >= 300 && status < 400) {
    return APIResponseStatusFamily.REDIRECT;
  } else if (status >= 400 && status < 500) {
    return APIResponseStatusFamily.CLIENT_ERROR;
  } else if (status >= 500 && status < 600) {
    return APIResponseStatusFamily.SERVER_ERROR;
  }
  return APIResponseStatusFamily.UNKNOWN;
};
